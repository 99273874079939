import React, { FC } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import ReactMarkdown from 'react-markdown';
import { List } from '../interfaces/welcome-center.interface';

export const GuideList: FC<{ list: List }> = ({ list }) => {
  return (
    <>
      <h2 className="f6 b lh-title">{list.mainTitle}</h2>
      {list.subTitle ? <p>{list.subTitle}</p> : null}
      <Accordion
        allowMultipleExpanded={true}
        allowZeroExpanded={true}
        className="guidelist pb6-l pb4"
      >
        {list.items.map((item, index) => (
          <AccordionItem className="guidelist__item pv4-l pv3" key={index + 1}>
            <AccordionItemHeading>
              <AccordionItemButton className="pointer guidelist__button flex justify-between">
                <span className="b">
                  {index + 1}. {item.title}
                </span>
                <span className="dropdown-button flex items-center"></span>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="pt2">
              <ReactMarkdown
                source={item.description}
                escapeHtml={false}
                renderers={{
                  paragraph: ({ children }) => <p className="mv2">{children}</p>,
                }}
              />
            </AccordionItemPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </>
  );
};
