import React, { FC } from 'react';
import { Grid, GridRow, GridCol } from './Grid';

export interface ResourceLinkInterface {
  title: string;
  description: string;
  linkUrl: string;
  linkText: string;
}

interface ResourceGridProps {
  resourceGrid: {
    title: string;
    resources: ResourceLinkInterface[];
  };
}

export const ResourcesGrid: FC<ResourceGridProps> = ({ resourceGrid }) => {
  return (
    <section className="bg-gray9 brandGray5">
      <div className="flex flex-row flex-wrap mw9 center ph4 pt7-l pb3-l pv5 mb0-l">
        <div className="w-25-l w-100">
          <h2 className="lh-title f6 f7-l fw7 mb0-l mb4 mt0">{resourceGrid.title}</h2>
        </div>

        <div className="w-75-l w-100">
          <Grid>
            <GridRow>
              {resourceGrid.resources.map((resource, index) => (
                <GridCol className="pr5-l mb6-l" width={2} key={index + 1}>
                  <h3 className="f5 fw7 mv0">{resource.title}</h3>
                  <p className="lh-copy h-100">{resource.description}</p>
                  <a href={resource.linkUrl} className="fw7 gt-after">
                    {resource.linkText}
                  </a>
                </GridCol>
              ))}
            </GridRow>
          </Grid>
        </div>
      </div>
    </section>
  );
};
