import React from 'react';
import classNames from 'classnames';

export interface GridProps {
  className?: string;
}

export const GridRow: React.FC<GridProps> = ({ children, className }) => (
  <div className={classNames('flex flex-wrap w-100', className)}>{children}</div>
);

export interface GridColumnProps extends GridProps {
  width?: 1 | 2 | 2.5 | 3 | 4;
}

export const GridCol: React.FC<GridColumnProps> = ({ children, className, width }) => (
  <div
    className={classNames(
      'flex flex-auto flex-none-l justify-center flex-column mb4 w-100',
      className,
      {
        'w-70-l': width === 1,
        'w-50-l': width === 2,
        'w-30-l': width === 2.5,
        'w-33-l': width === 3,
        'w-25-l': width === 4,
      }
    )}
  >
    {children}
  </div>
);

export const Grid: React.FC<GridProps> = ({ children, className }) => (
  <div className={classNames(className)}>{children}</div>
);
