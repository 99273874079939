import React from 'react';
import { GlobalStrings } from '../../../common/fragments/MRKConfig';
import { useStaticStrings } from '../../../common/hooks/useStaticStrings';

type strings =
  | 'Plan Type'
  | 'Set up your Free Plan'
  | 'Set up your Pro Plan'
  | 'Set up your Business Plan';

export const globalStringsKeysMapping: {
  [K in strings]: keyof GlobalStrings['json_values'];
} = {
  'Plan Type': 'Plan_type',
  'Set up your Free Plan': 'Set_up_your_Free_Plan',
  'Set up your Pro Plan': 'Set_up_your_Pro_Plan',
  'Set up your Business Plan': 'Set_up_your_Business_Plan',
};

const PlanHeading = (plan: 'free' | 'pro' | 'business') => {
  const staticStrings = useStaticStrings();

  const freePlanHeading =
    staticStrings.json_values[globalStringsKeysMapping['Set up your Free Plan']];
  const proPlanHeading =
    staticStrings.json_values[globalStringsKeysMapping['Set up your Pro Plan']];
  const businessPlanHeading =
    staticStrings.json_values[globalStringsKeysMapping['Set up your Business Plan']];

  if (plan === 'free') {
    return <h2 className="f6 b lh-title">{freePlanHeading}</h2>;
  } else if (plan === 'pro') {
    return <h2 className="f6 b lh-title">{proPlanHeading}</h2>;
  } else if (plan === 'business') {
    return <h2 className="f6 b lh-title">{businessPlanHeading}</h2>;
  }
  return null;
};

export default PlanHeading;
