import classNames from 'classnames';
import { string } from 'prop-types';
import React, { FC } from 'react';

interface HeaderProps {
  title: string;
  subtitle: string;
  subtitleLink?: string;
  belowSubtitleLink?: string;
  belowSubtitleLinkText?: string;
  orientation?: string;
  trackingCategory?: string;
  trackingAction?: string;
  trackingLabel?: string;
  fullHeight?: boolean;
}

export const HeroHeader: FC<HeaderProps> = ({
  title,
  subtitle,
  subtitleLink,
  orientation = 'center',
  belowSubtitleLink,
  belowSubtitleLinkText,
  trackingCategory,
  trackingAction,
  trackingLabel,
  fullHeight,
}) => {
  return (
    <section className={classNames('bg-cyan8 pv6', fullHeight && '.h-100')}>
      <div
        className={classNames('flex flex-column justify-center brandGray5', {
          'tc items-center ph4': orientation === 'center',
          'mw9 center ph3': orientation === 'left',
        })}
      >
        <h1
          className={classNames('f6mv0 b lh-title', {
            tc: orientation === 'center',
            'w-60-l': orientation === 'left',
          })}
        >
          {title}
        </h1>
        {subtitleLink ? (
          <a
            className="mt3 mw7 b"
            href={subtitleLink}
            data-tracking-category={trackingCategory}
            data-tracking-action={trackingAction}
            data-tracking-label={trackingLabel}
          >
            {subtitle}
          </a>
        ) : (
          <p className={classNames('mt3 mw7', { tc: orientation === 'center' })}>{subtitle}</p>
        )}

        {belowSubtitleLink ? (
          <a className="mt3 mw7 b" href={belowSubtitleLink}>
            {belowSubtitleLinkText}
          </a>
        ) : null}
      </div>
    </section>
  );
};
