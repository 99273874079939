import Layout from '../layout/layout';
import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { HeroHeader } from './common/HeroHeader';
import { Subnav } from './common/Subnav';
import { WelcomeCenterData, Plan } from './interfaces/welcome-center.interface';
import { PlanDropdown } from './common/PlanDropdown';
import { getPlansDropdown } from './common/PlanDropdownOptions';
import { Capitalize } from '../../common/helpers/string';
import { SetupContent } from './common/SetupContent';
import { LinkCTA } from './common/LinkCTA';
import { ResourcesGrid } from './common/ResourcesGrid';
import { useStaticStrings } from '../../common/hooks/useStaticStrings';
import RenderPlanHeading from './common/PlansHeading';
import { BladeSubNav } from '../common/blades/BladeSubNav';
import { initialPlan } from './activation';

export const query = graphql`
  query($locale: String!) {
    welcomeCenter(tabName: { eq: "core-setup" }, locale: { eq: $locale }) {
      ...welcomeCenterFragment
    }
    navData: mainTopNav(contentfulId: { eq: "3Ygy1wQCWTJl9iFRLTd0NV" }, locale: { eq: $locale }) {
      ...mainTopNavFragment
    }
    redwoodNavData: navNavigation(
      contentfulId: { eq: "5BEIZi0JdHhdU8ABizjFbY" }
      locale: { eq: $locale }
    ) {
      ...navigationFragment
    }
    redwoodRightNavData: navNavigation(
      contentfulId: { eq: "55yBvRCoANQ6LSDRS8y34S" }
      locale: { eq: $locale }
    ) {
      ...navigationFragment
    }
    utilityNavData: navNavigation(
      contentfulId: { eq: "3Ubk7uPrpCVkhQ3ZYXZLOp" }
      locale: { eq: $locale }
    ) {
      ...navigationFragment
    }
    footerData: mainFooter(locale: { eq: $locale }) {
      ...mainFooterFragment
    }
    navAbTestData: mainTopNav(
      contentfulId: { eq: "5g5R508U5ykm1WoKe5Q366" }
      locale: { eq: $locale }
    ) {
      ...mainTopNavAbTestFragment
    }
    subnav: bladeSubNav(contentfulId: { eq: "1e0eITYdkm5xNNEvoFpLzK" }, locale: { eq: $locale }) {
      ...subnavFragment
    }
    resourceGrid: bladeInfoBlocks(
      contentfulId: { eq: "5qZqpdha26WtvVPri2e2t7" }
      locale: { eq: $locale }
    ) {
      title
      relatedInfoBlocks {
        title
        description
        linkText
        linkUrl
      }
    }
  }
`;

const CoreSetup: React.FC<WelcomeCenterData> = ({ data, pageContext }) => {
  const { welcomeCenter } = data;
  const { mainHeader, subHeader, guideList } = welcomeCenter;
  const [currentPlan, setPlan] = useState<Plan>(initialPlan);
  const setupData = guideList.filter(
    item => item.metadata.plan.toLocaleLowerCase() === currentPlan.id.toLocaleLowerCase()
  );
  const staticStrings = useStaticStrings();

  const planType = staticStrings.json_values.Plan_type;

  const durationBadgesObj = staticStrings.json_values['DurationBadgeLevels'] as any;

  const plans = staticStrings.json_values.plans as any;
  const durationBadges = {
    free: durationBadgesObj.free,
    pro: durationBadgesObj.pro,
    business: durationBadgesObj.business,
  };

  return (
    <Layout
      redwoodNavData={data.redwoodNavData}
      redwoodRightNavData={data.redwoodRightNavData}
      utilityNavData={data.utilityNavData}
      pageContext={pageContext}
      topNavData={data.navData}
      topNavAbTestData={data.navAbTestData}
      footerData={data.footerData}
    >
      <Helmet>
        <title>Welcome Center | Cloudflare</title>
      </Helmet>

      <HeroHeader title={mainHeader} subtitle={subHeader}></HeroHeader>
      <BladeSubNav blade={data.subnav} pagePrefix={'/'} />

      <>
        <div className="flex flex-wrap mw9 center pt6-l pt4">
          <section className="w-20-l w-100 pl3-l pr6-l ph4">
            <h3 className="b mb2">{planType}</h3>
            <PlanDropdown
              options={getPlansDropdown(plans as any)}
              currentPlan={currentPlan}
              setPlan={setPlan}
            ></PlanDropdown>
          </section>

          <section className="w-60-l w-100 ph4">
            {RenderPlanHeading(currentPlan.id)}
            <SetupContent
              durationBadges={durationBadges}
              lists={setupData}
              introtext={welcomeCenter.introText}
              currentPlan={currentPlan}
            />

            <section className="flex flex-column flex-wrap mw8 center items-center pb7">
              <h2 className="w-100 f6 b center lh-title tc">{welcomeCenter.nextStepTitle}</h2>
              <LinkCTA url={welcomeCenter.nextStepButton.url}>
                {welcomeCenter.nextStepButton.text}
              </LinkCTA>
            </section>
          </section>
        </div>

        <ResourcesGrid
          resourceGrid={{
            title: data.resourceGrid.title,
            resources: data.resourceGrid.relatedInfoBlocks,
          }}
        ></ResourcesGrid>
      </>
    </Layout>
  );
};
export default CoreSetup;
