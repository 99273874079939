import React, { FC, useState } from 'react';
import { Link } from 'gatsby';
import { Plan } from '../interfaces/welcome-center.interface';

export interface DropdownProps {
  setPlan: (plan: Plan) => void;
  currentPlan: Plan;
  options: Plan[];
}

export const PlanDropdown: FC<DropdownProps> = ({ children, options, currentPlan, setPlan }) => {
  return (
    <select
      value={currentPlan.id}
      onChange={e => setPlan(options.find(x => x.id === e.target.value) || options[0])}
      className="w-100 db lh-copy brandGray5 h2 ph1 bg-white br2"
    >
      {options.map(option => {
        return (
          <option value={option.id} key={option.id}>
            {option.display}
          </option>
        );
      })}
    </select>
  );
};
