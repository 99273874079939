import React, { FC } from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';
import { LocaleLink } from '../../common/links/LocaleLink';

export interface LinkProps {
  className?: string;
  url: string;
}

export const LinkCTA: FC<LinkProps> = ({ children, className, url }) => {
  return (
    <LocaleLink
      className={classNames(
        'f3 fw7 ba br2 bw2 bg-blue1 b--blue1 white tc pv3 ph4 pointer lh-copy ls2 db',
        className
      )}
      to={url}
    >
      {children}
    </LocaleLink>
  );
};
