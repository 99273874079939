import React, { FC } from 'react';
import { useStaticStrings } from '../../../common/hooks/useStaticStrings';

interface DurationBadgeProps {
  time: string;
  level: string;
}

export const DurationBadge: FC<DurationBadgeProps> = ({ children, time, level }) => {
  const staticStrings = useStaticStrings();

  return (
    <button className="f3 fw7 ba br2 b--gray8 bw2 bg-gray9 tc pv2 ph2 pointer flex flex-row">
      <div className="mr3">
        <span className="b">{staticStrings.json_values.Time}:</span>
        <span> {time}</span>
      </div>
      <div>
        <span className="b">{staticStrings.json_values.Level}:</span>
        <span> {level}</span>
      </div>
    </button>
  );
};
