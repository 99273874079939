import { Plan } from '../interfaces/welcome-center.interface';

export type PlansType = {
  [string: string]: {
    id: 'free' | 'pro' | 'business';
    displayName: string;
  };
};

export const getPlansDropdown = (plans: PlansType) => {
  let plansArray: Plan[] = [];
  for (const key in plans) {
    plansArray.push({
      id: plans[key].id,
      display: plans[key].displayName,
    });
  }
  return plansArray;
};
