import React, { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import { DurationBadgeLevels } from './DurationBadgeLevels';
import { DurationBadge } from './DurationBage';
import { GuideList } from './GuideList';
import { DurationBadgeLevelsInterface, List, Plan } from '../interfaces/welcome-center.interface';
export const SetupContent: FC<{
  lists: List[];
  introtext: string;
  currentPlan: Plan;
  durationBadges: DurationBadgeLevelsInterface;
}> = ({ children, lists, introtext, currentPlan, durationBadges }) => {
  const planId = currentPlan.id.toLocaleLowerCase();
  const durationBadge = durationBadges[planId];
  return (
    <>
      <DurationBadge time={durationBadge.time} level={durationBadge.level}></DurationBadge>
      <ReactMarkdown
        source={introtext}
        escapeHtml={false}
        renderers={{
          paragraph: ({ children }) => <p className="pb5">{children}</p>,
        }}
      />
      {lists.map((list, index) => (
        <GuideList list={list} key={index + 1}></GuideList>
      ))}
    </>
  );
};
